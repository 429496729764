.ProjectDetail img, p {
  width: 100%;
}

main > section {
  width: 50%;
  display: flex;
  justify-content: center;
}

.ProjectDetail > div {
  box-shadow: 0 0 20px 10px rgba(66, 66, 66, 0.75);
}

.ProjectDetail ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 0 ;
}

.ProjectDetail li {
  padding: 0 20px;
  color: var(--text-color); 
}

.ProjectDetail p {
  color: var(--text-color);
  font-size: .85em;
}

.ProjectDetail span {
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  main > section {
    width: 80%;
  }

  .ProjectDetail p {
    padding: 1% 0 0 0;
  }

  .ProjectDetail li {
    font-size: .75em;
  }
  
}
