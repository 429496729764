.contact {
  background-image: url('../../../public/portfolio-contact-d.png');
  background-size: cover;
}

.contact section {
  display: flex;
  justify-content: center;  
  align-items: center;
  width: 60%;
}

.contact article {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  padding: 1.6% 0 0 0;
}

.contact h1 {
  text-align: center;
  font-size: 2.8em;
  font-weight: 200;
  padding: 0 0 10px 0;
  color: var(--text-color);
}

.contact h6 {
  text-align: center;
  font-size: 1.25em;
  font-weight: 300;
  padding: 2%;
  color: var(--text-color);
}

.contact p {
  padding: 0 0 0 5px;
  font-size: 1.35em;
  color: var(--text-color);
  background-color: none;
  text-align: left;
  margin: 0;
  width: fit-content;
}

.contact span {
  display: flex;
  justify-items: flex-start;
  width: 60%;
  padding: 0 10px;
  background-color: none;
}

@media only screen and (max-width: 768px) {
  .contact section {
    width: 70%;
  }

  .contact article {
    width: 100%;
    justify-content: flex-start;
  }

  .contact a {
    width: 50%;
  }

  .contact h6 {
    font-size: 1.2em;
    padding: 10% 0;
  }
}