.experience {
  background-image: url('../../../public/portfolio-experience-d.png');
  background-size: cover;
}

.experience section {
  width: 80%;
}

.experience h1 {
  text-align: center;
  font-size: 2em;
  padding: 0 0 2% 0;
  color: var(--text-color);
}

.experience article {
  display: flex;
  justify-content: center;
  align-items: baseline;
  width: 100%;
  padding: 2% 0 0 0;
}

.experience span {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  padding: 0 20px;
  width: 100%;
}

.experience h2, summary {
  font-size: 1.5em;
  padding: 0 0 0 10px;
  color: var(--text-color);
}

details {
  width: 100%;
}

.experience ul {
  display: flex;
  /* flex-direction: column; */
  justify-content: flex-start;
  align-items: baseline;
  padding: 0 20px;
  width: 60%;
  flex-wrap: wrap;
  font-size: 1em; 
  margin: 0;
  width: fit-content;
}

.experience p {
  font-size: 2em;
  margin: 0 10px;
}

.experience li {
  font-size: .8em;
  margin: 5px;
  background-color: gray;
  color: white;
  padding: 0 2px;
  border-radius: 3px;
  line-height: 2.5;
}

@media only screen and (max-width: 768px) {
  article {
    width: 100vw;
    margin: 0 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .experience summary {
    width: 100%;
    margin: 0%;
  }

  .experience details {
    width: 80%;
    margin: 0 20%;
    padding: 2%;
  }

  .experience h1 {
    text-align: center;
    font-size: 2em;
    padding: 0 0 8% 0;
  }

  .expericence span {
    margin: 0 0 0 40%;
  }
}