.about {
  background-image: url('../../../public/portfolio-about-d.png');
  background-size: cover;
  height: 100vh;
}

.about main {
  width: 100vw;
}

.about section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 70%;
}

.about div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  width: 55%;
  margin: 0 0 0 20px;
}

.about img {
  width: 30%;
  height: 30%;
  border: 2px solid white;
  margin: 0 0 5% 0;
  box-shadow: 0 0 20px 10px rgba(66, 66, 66, 0.75);
}

.about p {
  width: 100%;
  padding: 0 3%;
  font-size: 1.1em;
  font-weight: 200;
  color: var(--text-color);
}

.about article {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  width: 100%;
  padding: 1% 0 0 0;
}

.about span {
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 0 20px;
  width: 100%;
}

.about h2, .about summary {
  font-size: 1.25em;
  padding: 0 0 0 10px;
  color: var(--text-color);
  width: 100%;
  white-space: nowrap;
}

summary {
  font-weight: 500;
}

details {
  width: 15%;
}

.about ul {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  padding: 0 10px 0 20px;
  width: 100%;
  flex-wrap: wrap;
  margin: 0;
  width: fit-content;
}

.about h6 {
  font-size: 2em;
  margin: 0 10px;
}

.about li {
  font-size: .7em;
  margin: 5px;
  background-color: gray;
  color: white;
  padding: 5px;
  border-radius: 3px;
  line-height: 1.1;
}

@media only screen and (max-width: 768px) {

  .about {
    height: 110vh;
    width: 100vw;
  }

  .about section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
  }
  
  .about div {
    width: 90%;
  }
  
  .about summary {
    margin: 0 0 0 20px;
    width: 100%;
  } 
  .about p {
    width: 95%;
    padding: 0;
    font-size: .9em;
    font-weight: 300;
    padding: 4% 0;
  }

  .about img {
    width: 45%;
    height: 45%;
    margin-top: 8%;
  }

  .about article {
    justify-content: center;
    align-items: flex-start;
    padding: 0 0 0 10%;
    width: 90%;
  }
}