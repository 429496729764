
.projects {
  background-image: url('../../../public/portfolio-projects-d.png');
  background-size: cover;
  height: 100%;
  padding: 8% 0 0 0;
  justify-content: center;
  flex-direction: column;
}

.projects h1 {
  padding: 2% 0;
  font-size: 2em;
  font-weight: 200;
  color: white;
}

.projects section {
  display: flex;
  flex-direction: row;
  width: 60%;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 3% 0;
}

.projects img {
  width: 35%;
  box-shadow: 0 0 20px 5px rgba(82, 82, 82, 0.75);
}

.projects p {
  color: white;
}

article {
  width: 57%;
  padding: 0 0 0 5%;
}

.projects a:hover {
  color: rgb(255, 162, 138) ;
}


.Projects_projects h3, h4 {
  color: var(--text-color);
}

.projects h4 {
  padding: 5% 0 1% 0;
  font-size: 1.25em;
  /* width: 50%; */
  /* text-align: left; */
}

.projects a {
  font-size: .9em;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  color: var(--text-color);
  padding: 0 0 10% 0;
}

.projects span {
  padding: 2% 0 0 0;
  width:fit-content;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .projects {
    height: 100%;
    padding: 25% 0 0 0;
  }

  .projects section {
    width: 70%;
    display: flex;
    flex-direction: column;
  }

  .projects img {
    width: 100%;
    height: 100%;
  }

  .projects article {
    width: 100%;
    padding: 0;
    margin: 0;
    padding: 5% 0 0 0;
  }

  .projects div {
    width: 80%;
    padding: 0 0 3% 0;
  }

  .projects h1 {
    font-size: 1.5em;
    padding: 0 0 10% 0;
  }

  .projects h4 {
    padding: 8% 0 0 0;
    font-size: 1em;
  }

  .projects > a {
    font-size: 1em;;
    padding: 5% 0 10% 0;
    text-align: center;
  }

  .projects p {
    font-size: .85em;
  }

}