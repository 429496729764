html {
  box-sizing: border-box
}

/* The Universal Selector */
*, /* All elements*/
*::before, /* All ::before pseudo-elements */
*::after { /* All ::after pseudo-elements */
  /* height & width will now include border & padding by default
     but can be over-ridden as needed */
  box-sizing: inherit;
}

.App {
  text-align: center;
}

body {
  background-color: black;
  --text-color: white;
  font-family: 'Poppins', sans-serif;
}

/* body {
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	height: 100vh;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
} */

nav {
  color: var(--text-color);
  position: fixed; 
  height: 12vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

main {
  width: 100vw;
  height: 100vh;
  padding: 6vh 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

section {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

a {
  color: var(--text-color); 
  text-decoration: none;
}

a:hover {
  color: rgb(255, 162, 138);
}

li {
  list-style: none;
}

@media only screen and (max-width: 768px) {
  nav {
    width: 100vw;
    margin: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}

@media only screen and (min-width: 1200px) {
  /* .event {
    grid-template-columns: repeat(3, 1fr);
  } */
}

@media only screen and (min-width: 1500px) {
  /* .event {
    grid-template-columns: repeat(4, 1fr);
    width: 80%;
  } */
}