nav {
  backdrop-filter: blur(10px);
}

.NavBar nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
} 

.NavBar ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
} 

.NavBar li {
  padding: 0 2%;
  font-size: 1.2em;
  justify-content: space-evenly;
}

.NavBar a:hover {
  border-bottom: 1px solid white;
}

.NavBar a:active {
  color: rgb(255, 162, 138);
    border-bottom: 1px solid white;
}

@media only screen and (max-width: 768px) {

  .NavBar li {
    font-size: 1em;
  }
}