.home {
  background-image: url('../../../public/portfolio-home-d.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.home img {
  width: 120%;
  height: 120%;
  float: right;
}

.home div {
  float: left;
  width: 20%;
  z-index: 10;
  margin-left: 52%;
  margin-top: -17%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 2em;
  font-weight: 300;
}

.home a:hover {
  font-weight: 900;
}

.mobile {
  display: none;
}

@media only screen and (max-width: 768px){

  .home section {
    width: 80%;
  }

  .standard {
    display: none;
  }

  .mobile{
    display: block;
  }

  .home img {
    width: 85%;
    height: 85%;
    margin-top: -40%;
  }

  .home div {
    font-size: 1.2em;
    margin-left: -20%;
    margin-top: -72%;
  }

}

@media only screen and (max-width: 1024px) {

  .home img {
    width: 85%;
    height: 85%;
  }

  .home div {
    font-size: 1.4em;
  }

}